import React, { useEffect } from "react";
import Layout from "../components/layout";
import SvgIcons from "../components/svgIcons/svgIcons";
const Delivery = () => {
  useEffect(() => {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;

    s.src =
      "https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A057cbef470daff9c8245355bcc106bbd17dc3ee0e11bafd8c11b894a263b5da8&amp;width=100%&amp;height=600&amp;lang=ru_RU&amp;scroll=true";

    const h = document.getElementById("yandexmap");
    h.parentNode.insertBefore(s, h);
  }, []);

  return (
    <Layout>
      <div className="container content_header text-center mt-60">
        <h1>ДОСТАВКА ПО РОССИИ</h1>
        <div className="mt-60">
          <p className="p20">
            Для доставки Вашего заказа мы используем автомобильный или
            железнодорожный транспорт, что позволяет доставить заказ во все
            регионы России. Доставка осуществляется на Ваш адрес или на адрес
            Вашего контрагента..
          </p>
        </div>
      </div>

      <div className="container mt-30">
        <div className="info mt-30 mb-30 ">
          <p>
            Наша компания бесплатно осуществляет доставку Вашего заказа до
            транспортной компании. Транспортные компании, которые мы
            рекомендуем:{" "}
          </p>
        </div>
        <div className="ulList">
          <ul>
            <li>ООО «Деловые линии»</li>
            <li>ООО «ЖелДорЭкспедиция»</li>
            <li>ООО «ПЭК» (Первая Экспедиционная компания)</li>
            <li>ООО «СЗТКом» (Северо-Западная транспортная компания)</li>
            <li>ООО «КИТ-Сервис»</li>
          </ul>
        </div>
        <div className="info mt-30">
          <p>
          В случае необходимости мы готовы рассмотреть другие транспортные компании удобные Вам.
          </p>
        </div>
        <div className="cardTwo mt-60">
          <div className="cardTwo__block">
            <div className="cardTwo__header">ДОСТАВКА ПО САНКТ-ПЕТЕРБУРГУ</div>
            <div className="cardTwo__description">
              Условия доставки в пределах Санкт-Петербурга вы можете уточнить у
              менеджера отдела продаж в момент формирования заявки или по
              телефону: +7 (812) 640-30-30 или +7 (800) 333-40-30
            </div>
          </div>
          <div className="cardTwo__block">
            <div className="cardTwo__header">САМОСТОЯТЕЛЬНЫЙ ВЫВОЗ</div>
            <div className="cardTwo__description">
              Вы или Ваше доверенное лицо можете самостоятельно забрать заказ со
              склада нашей компании с 10:00 до 16:30 в рабочие дни.
            </div>
          </div>
        </div>
        <div className="info mt-60 mb-60 ">
          <div className="attention">
            <div className="attention__img">
              <SvgIcons
                width="64"
                height="64"
                name="attention"
                className="icon_svg price"
              />
            </div>
            <div className="attention__description">
              <p>
              При самостоятельном вывозе заказа доверенным лицом необходимо предъявить доверенность на получение заказа или иметь при себе печать компании заказчика.
              </p>
              <br />
              <p>
              Сроки комплектации и доставки уточняйте у Вашего менеджера по телефонам:
              </p>
              <div className="ulList mt-30">
                <ul>
                  <li>+7 (812) 640-30-30; </li>
                  <li>+7 (800) 333-40-30</li>
                  <li>по электронной почте: office@dendor.ru</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mapDelivery">
          <div id="yandexmap"></div>
        </div>
      </div>
    </Layout>
  );
};

export default Delivery;
